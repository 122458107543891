<template>
    <div>

        <modal dialog title="Success" ref="productAddSuccess" width="25r" headerColor="primary"
               bodyColor="primary" okButtonColor="primary">
            Product added successfully

        </modal>

        <modal dialog title="Are you sure.?" ref="productAdd" width="25r" headerColor="primary"
               bodyColor="primary" okButtonColor="primary" okButton="Yes" cancel-button="No"
               cancelButtonColor="info">
            Are you sure want to add this product without editing the price ?

            <form role="form" class="mt-4">
                <div class="row">
                    <div class="col-md-6">
                        <validated-input disabled name="SellingPrice" label="Selling Price"
                                         v-model="addProductModel.selling_price" :rules="rules.selling_price"/>
                    </div>
                    <div class="col-md-6">
                        <validated-input disabled name="DisplayPrice" label="Display Price"
                                         v-model="addProductModel.display_price" :rules="rules.display_price"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="productmasterid" label="product master"
                                         v-model="addProductModel.product_master_id" :rules="rules.product_master_id"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="categoryid" label="category" v-model="addProductModel.category_id"
                                         :rules="rules.category_id"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="shopid" label="Shop" v-model="addProductModel.shop_id"
                                         :rules="rules.shop_id"/>
                    </div>
                </div>
            </form>

        </modal>

        <modal dialog title="Are you sure.?" ref="productEditAdd" width="25r" headerColor="primary"
               bodyColor="primary" okButtonColor="primary" okButton="Yes" cancel-button="No"
               cancelButtonColor="info">
            Are you sure want to edit the price ? the edited price will be reflected in your shop's catalog

            <form role="form" class="mt-4">
                <div class="row">
                    <div class="col-md-6">
                        <validated-input name="SellingPrice" label="Selling Price"
                                         v-model="addProductModel.selling_price" :rules="rules.selling_price"/>
                    </div>
                    <div class="col-md-6">
                        <validated-input name="DisplayPrice" label="Display Price"
                                         v-model="addProductModel.display_price" :rules="rules.display_price"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="productmasterid" label="product master"
                                         v-model="addProductModel.product_master_id" :rules="rules.product_master_id"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="categoryid" label="category" v-model="addProductModel.category_id"
                                         :rules="rules.category_id"/>
                    </div>
                    <div class="col-md-6" style="display: none">
                        <validated-input name="shopid" label="Shop" v-model="addProductModel.shop_id"
                                         :rules="rules.shop_id"/>
                    </div>
                </div>
            </form>

        </modal>

        <validated-input icon="fa fa-search" v-model="search" clear-icon="fa fa-close"/>
        <infinite-scroll-container class="product-list" @load-next="loadNext">
            <div class="product-card bs-1" v-for="(product, index) in products" v-bind:key="index">
                <img src="../../../assets/img/No_image_available.svg" class="product-img" :alt="product.name"
                     v-if="!product.img"/>
                <img :src="product.img" alt="Image" class="product-img" v-else>
                <strong class="text-uppercase mt-2 text-primary">{{ product.name }}</strong>
                <hr/>
                <div class="product-footer">
                    <div class="price">
                        Rs.
                        <cur class="display-price" :value="product.display_price"
                             v-if="product.display_price !== product.selling_price"/>
                        <cur class="selling-price" :value="product.selling_price"/>
                    </div>
                    <div class="add-to-cart my-3 bs-11" :class="product.loading ? 'loading': ''">
                        <div class="btn-group-tight">
                            <c-btn color="warning" icon="fa fa-pencil" @click.prevent="EditAndAddItem(product)"/>
                            <c-btn color="success" icon="fa fa-plus"
                                   @click.prevent="AddItem(product)"/>
                        </div>

                    </div>
                </div>
            </div>
        </infinite-scroll-container>
        <Loader v-if="loading"></Loader>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapActions, mapGetters } from 'vuex';
import CardButton from '../../../components/CardButton';
import Loader from '../../../views/loader/loader';
import InfiniteScrollContainer from '../../../components/InfiniteScroll';
import debounce from 'lodash.debounce';
import Cur from '../../../components/Currency';

export default {
    name: 'ProductCatalog',
    components: {
        Cur,
        InfiniteScrollContainer,
        'c-btn': CardButton,
        Loader
    },
    data () {
        return {
            product: '',
            loading: false,
            prodLoading: false,
            page: 0,
            isLast: false,
            search: '',
            addProductModel: {
                id: '',
                product_master_id: '',
                shop_id: '',
                category_id: '',
                display_price: '',
                selling_price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            products: [],
            data: []
        };
    },

    watch: {
        search: debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },

    computed: {
        ...mapActions(['setCart']),
        ...mapGetters(['currentUser'])
    },

    methods: {
        setData (response) {
            const items = response.data.data;
            if (items.length === 0) {
                this.isLast = true;
                return;
            }
            if (items) {
                this.products = [
                    ...this.products,
                    ...items
                ];
            }
        },
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.shopProduct.shopProductAdminList, {
                params: {
                    search: this.search,
                    id: 1,
                    page: this.page,
                    per_page: 20
                }
            });
            this.loading = false;
            this.setData(response);
        },

        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },

        async AddItem (rowData) {
            this.addProductModel = {
                id: rowData.id,
                product_master_id: rowData.product_master_id,
                shop_id: rowData.shop_id,
                category_id: rowData.category_id,
                display_price: rowData.display_price,
                selling_price: rowData.selling_price
            };
            const modal = this.$refs.productAdd;
            const response = await modal.show();
            if (response === true) {
                await this.addOrEditProduct(rowData);
            } else {

            }
        },

        async EditAndAddItem (rowData) {
            this.addProductModel = {
                id: rowData.id,
                product_master_id: rowData.product_master_id,
                shop_id: rowData.shop_id,
                category_id: rowData.category_id,
                display_price: rowData.display_price,
                selling_price: rowData.selling_price
            };
            const modal = this.$refs.productEditAdd;
            const response = await modal.show();
            if (response === true) {
                await this.addOrEditProduct(rowData);
            } else {

            }
        },
        async addOrEditProduct (rowData) {
            this.addProductModel = {
                id: rowData.id,
                product_master_id: rowData.product_master_id,
                shop_id: rowData.shop_id,
                category_id: rowData.category_id,
                display_price: rowData.display_price,
                selling_price: rowData.selling_price
            };
            const url = urls.shopProduct.create;
            this.$set(rowData, 'loading', true);
            const response = await axios.form(url, this.addProductModel);
            console.log('add item response', response);

            const arr = this.products;
            const index = arr.map(function (o) {
                return o.id;
            }).indexOf(this.addProductModel.id);
            arr.splice(index, 1);
            this.$set(rowData, 'loading', false);
            const modal = this.$refs.productAddSuccess;
            await modal.show();
        },

        EditItem (item) {
            this.model = {
                id: item.id,
                selling_price: item.selling_price,
                display_price: item.display_price,
                shop_id: item.shop_id
            };
            this.openModal();
        },

        openModal () {
            this.$refs.modal.show();
        }
    }
}
;

</script>
